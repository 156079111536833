import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { FETCH_POKEMON_BY_ID } from './queries/fetchPokemonById'
import { type Pokemon, MessageLevel } from './types'
import Header from './components/Header'
import Message from './components/Message'
import Card from './components/Card'
import PokemonCard from './components/PokemonCard'
import Controls from './components/Controls'
import Footer from './components/Footer'

function App() {
  // config
  const appTitle = 'Pokédex'
  const author = 'Tyler Etters'
  const githubUrl = 'https://github.com/tyleretters/pokedex'
  const minPokemonId = 1
  const maxPokemonId = 151

  // state
  const { id } = useParams()
  const navigate = useNavigate()
  const [pokemonId, setPokemonId] = useState(Number(id) || 1)

  // url params
  useEffect(() => {
    if (id) {
      const parsedId = parseInt(id, 10)
      if (parsedId >= minPokemonId && parsedId <= maxPokemonId) {
        setPokemonId(parsedId)
      }
    }
  }, [id])

  useEffect(() => {
    navigate(`/id/${pokemonId}`, { replace: true })
  }, [pokemonId, navigate])

  // controls
  const increment = () => {
    setPokemonId((prevPokemonId) =>
      prevPokemonId < maxPokemonId ? prevPokemonId + 1 : prevPokemonId
    )
  }

  const decrement = () => {
    setPokemonId((prevPokemonId) =>
      prevPokemonId > minPokemonId ? prevPokemonId - 1 : prevPokemonId
    )
  }

  const randomize = () => {
    setPokemonId(() =>
      getRandomPokemonId(minPokemonId, maxPokemonId, pokemonId)
    )
  }

  // helpers
  const getRandomPokemonId = (minPokemonId: number, maxPokemonId: number, currentPokemonId: number): number => {
    let newPokemonId
    do {
      newPokemonId = Math.floor(Math.random() * (maxPokemonId - minPokemonId + 1)) + minPokemonId
    } while (newPokemonId === currentPokemonId)
    return newPokemonId
  }

  // query
  const { loading, error, data } = useQuery(FETCH_POKEMON_BY_ID, {
    variables: { id: pokemonId },
    fetchPolicy: 'cache-first',
  })

  // render
  const renderContent = () => {
    if (loading) return <Message copy="Loading..." />
    if (error)
      return (
        <Message
          copy="Error. Check console and network in dev tools."
          level={MessageLevel.ERROR}
        />
      )
    if (data)
      return (
        <PokemonCard
          pokemon={data.pokemon_v2_pokemon_aggregate.nodes[0] as Pokemon}
        />
      )
  }

  return (
    <div className="container mt-10 px-4">
      <div className="columns-1">
        <Header title={appTitle} />
        <div className="mb-10 w-full">
          <Card contents={renderContent()} />
          <Controls
            currentPokemonId={pokemonId}
            minPokemonId={minPokemonId}
            maxPokemonId={maxPokemonId}
            onPrevClick={decrement}
            onNextClick={increment}
            onRandomClick={randomize}
          />
        </div>
        <Footer author={author} githubUrl={githubUrl} />
      </div>
    </div>
  )
}

export default App
