import { ApolloClient, InMemoryCache, type InMemoryCacheConfig } from '@apollo/client'

const options: InMemoryCacheConfig = {
  resultCaching: true
}

const client = new ApolloClient({
  uri: 'https://beta.pokeapi.co/graphql/v1beta',
  cache: new InMemoryCache(options)
})

export default client