import React, { ReactNode } from 'react'

interface CardProps {
  contents: ReactNode
}

export default function Card({ contents }: CardProps) {
  return (
    <div className="mx-auto mb-4 flex h-[120px] w-auto max-w-[384px] items-center space-x-4 rounded-lg bg-white p-3 shadow-lg shadow-black">
      {contents}
    </div>
  )
}
