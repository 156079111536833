export interface Pokemon {
  name: string
  pokemon_species_id: number
  pokemon_v2_pokemonsprites: { sprites: string }[]
  pokemon_v2_pokemontypes: { pokemon_v2_type: { name: string } }[]
}

export enum MessageLevel {
  NORMAL = 'normal',
  ERROR = 'error'
}