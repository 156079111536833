import React from 'react'
import { MessageLevel } from '../types'

interface MessageProps {
  copy: string
  level?: MessageLevel
}

export default function Message({ copy, level = MessageLevel.NORMAL }: MessageProps) {
  return (
    <p className="w-full text-center">
      <span className={level === MessageLevel.NORMAL ? 'text-black' : 'text-red-500'}>
        {copy}
      </span>
    </p>
  )
}
