import React from 'react'

interface FooterProps {
  author: string
  githubUrl: string
}

export default function Footer({ author, githubUrl }: FooterProps) {
  const year = new Date().getFullYear()

  return (
    <footer className="mb-10 flex w-full justify-center font-mono text-gray-400">
      <p>
        {year}, by {author} |{' '}
        <a
          href={githubUrl}
          className="underline hover:text-yellow-100 hover:no-underline"
        >
          GitHub
        </a>
      </p>
    </footer>
  )
}
