import React, { useState, useEffect } from 'react'
import type { Pokemon } from '../types'

interface PokemonCardProps {
  pokemon: Pokemon
}

export default function PokemonCard({ pokemon }: PokemonCardProps) {
  const name = pokemon.name
  const id = pokemon.pokemon_species_id
  const sprite = pokemon.pokemon_v2_pokemonsprites[0].sprites
  const type = pokemon.pokemon_v2_pokemontypes[0].pokemon_v2_type.name

  const [opacityClass, setOpacityClass] = useState('opacity-0')

  useEffect(() => {
    const transitionDuration = 100
    setOpacityClass('opacity-0')
    const timeoutId = setTimeout(() => {
      setOpacityClass('opacity-100')
    }, transitionDuration)
    return () => clearTimeout(timeoutId)
  }, [pokemon])

  return (
    <>
      <div className="shrink-0">
        <img
          className={`size-24 transition-opacity duration-500 ${opacityClass}`}
          src={sprite}
          alt={type}
        />
      </div>
      <div>
        <h2 className="text-xl font-medium capitalize text-black" data-test="pokemonName">{name}</h2>
        <p className="text-slate-500">
          Type: <span className="capitalize">{type}</span>
        </p>
        <p className="text-slate-500">
          ID: <span className="capitalize">{id}</span>
        </p>
      </div>
    </>
  )
}
