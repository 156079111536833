import { gql } from '@apollo/client'

export const FETCH_POKEMON_BY_ID = gql`
  query fetchPokemonById($id: Int!) {
    pokemon_v2_pokemon_aggregate(limit: 1, where: {id: {_eq: $id}}) {
      nodes {
        name
        pokemon_species_id
        pokemon_v2_pokemonsprites {
          sprites(path: "front_default")
        }
        pokemon_v2_pokemontypes {
          pokemon_v2_type {
            name
          }
        }
      }
    }
  }
`