import React from 'react'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string
  isDisabled?: boolean
  onClick: () => void
}

export default function Button({
  text,
  isDisabled = false,
  onClick,
  ...rest
}: ButtonProps) {
  return (
    <button
      className={`rounded-lg px-3 py-1 ${
        isDisabled
          ? 'cursor-not-allowed bg-gray-300 text-gray-500'
          : 'bg-green-500 hover:bg-green-200 hover:text-black'
      }`}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      {text}
    </button>
  )
}
