import React from 'react'
import Button from './Button'

interface ControlsProps {
  currentPokemonId: number
  minPokemonId: number
  maxPokemonId: number
  onPrevClick: () => void
  onNextClick: () => void
  onRandomClick: () => void
}

export default function Controls({
  currentPokemonId,
  minPokemonId,
  maxPokemonId,
  onPrevClick,
  onNextClick,
  onRandomClick,
}: ControlsProps) {
  return (
    <div className="mx-auto mb-10 grid w-full max-w-sm grid-cols-3 space-x-4">
      <Button
        text="👈 Prev"
        isDisabled={currentPokemonId === minPokemonId}
        onClick={onPrevClick}
        data-test="prevPokemon"
      />
      <Button
        text="🎲 Random"
        onClick={onRandomClick}
        data-test="randomPokemon"
      />
      <Button
        text="Next 👉"
        isDisabled={currentPokemonId === maxPokemonId}
        onClick={onNextClick}
        data-test="nextPokemon"
      />
    </div>
  )
}
