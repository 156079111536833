import React from 'react'

interface HeaderProps {
  title: string
}

export default function Header({ title }: HeaderProps) {
  return (
    <header className="mb-4 w-full">
      <h1 className="text-center text-3xl">{title}</h1>
    </header>
  )
}
